import React from "react";
import "../../styles/components/admin/list.scss";

export const ContactList = ({ contacts, isFetching }) => {
    //Small waiting message while request for data is fulfilled
    //Used inline style for convenience, only briefly shown.
    return isFetching ? (
        <div className="Users-List-Container">
            <div className="users-list">
                <h2>Contact Requests</h2>
                <div style={{ justifyContent: "center" }}>
                    <h3>Loading table, please wait...</h3>
                </div>
            </div>
        </div>
    ) : !contacts ? (
        <div className="Users-List-Container">
            <div className="users-list">
                <h2>Contact Requests</h2>
                <div style={{ justifyContent: "center" }}>
                    <h3>There are no contact requests to display.</h3>
                </div>
            </div>
        </div>
    ) : (
        <div className="Users-List-Container">
            <div className="users-list">
                <h2>Contact Requests</h2>
                <div className="variables-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Messages</th>
                                <th>Submitted at</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.reverse().map((contact, key) => (
                                <tr
                                    key={key}
                                >
                                    <td key={`row${key}_data0`}>
                                        {contact.name}
                                    </td>
                                    <td key={`row${key}_data2`}>
                                        {contact.phone}
                                    </td>
                                    <td key={`row${key}_data3`}>
                                        {contact.email}
                                    </td>
                                    <td key={`row${key}_data4`}>
                                        {contact.message}
                                    </td>
                                    <td key={`row${key}_data5`}>
                                        {contact.createdAt}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
