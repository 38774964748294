import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import history from "../../history";

// Styles
import "../../styles/components/login/loginComponent.scss";

// Assets

// Components
import LoginForm from "./loginForm.component";
import { Card } from "../card/Card";
import { PageSpacer } from "../blocks/PageSpacer";
// Actions
import { login } from "../../actions/login.action";

class Login extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(form) {
        let projectName = `${process.env.REACT_APP_PROJECT_NAME}`;
        this.props
            .login(form)
            .then((res) => {
                localStorage.setItem(`${projectName}::access_token`, res.access_token);
                history.push("/dashboard/");
            })
            .catch((res) => {
                alert(res.message);
            });
    }

    render() {
        return (
            <div className="login-container">
                <PageSpacer />
                <div className="login">
                    <Card title="Log In">
                        <LoginForm onSubmit={this.onSubmit} />
                    </Card>
                </div>
                <PageSpacer />
            </div>
        );
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
};

export const LoginComponent = connect(null, { login })(Login);
