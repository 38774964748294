import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { reset } from "redux-form";

// Components
import { BackgroundImage } from "../blocks/backgroundImage";
import { ContactForm } from "./contactForm";

// Actions
import { sendContactRequest } from "../../actions/contactAction";

// Style
import "../../styles/components/contact/contact.scss";

//Assets
import contactImage from "../../assets/images/contact-us-img-1.jpg";
import contactImageWebP from "../../assets/images/contact-us-img-1.webp";
import contactImageMobile from "../../assets/images/mobile/contact-us-img-1.jpg";
import contactImageWebPMobile from "../../assets/images/mobile/contact-us-img-1.webp";

// CMS
import CMSSection from "../../components/cms/cms";

const ContactComponent = ({ innerRef, sendContactRequest, reset, cms }) => {
    const onSubmit = async (data) => {
        const requestType = "POST";
        try {
            const response = await sendContactRequest(data, requestType);
            reset();
            Swal.fire("Message Sent!", response.message, "success");
        } catch (e) {
            Swal.fire("Error Sending Message!", e.message, "error");
        }
    };

    return (
        <CMSSection name="Contact">
            {({ cmsElmt }) => (
                <div className="contact-container" ref={innerRef}>
                    <CMSSection name="Background Image">
                        {({ cmsElmt }) => (
                            <BackgroundImage
                                image={cmsElmt(
                                    "Background Image (Desktop)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/contact-us-img-1.jpg`
                                )}
                                imageWebP={cmsElmt(
                                    "Background Image (Desktop - webp)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/contact-us-img-1.webp`
                                )}
                                mobile={cmsElmt(
                                    "Background Image (Mobile)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/mobile/contact-us-img-1.jpg`
                                )}
                                mobileWebP={cmsElmt(
                                    "Background Image (Mobile - webp)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/mobile/contact-us-img-1.webp`
                                )}
                            />
                        )}
                    </CMSSection>
                    <div className="content-container">
                        <div className="contact-title">
                            <h2>{cmsElmt("Header", "text", "CONTACT US")}</h2>
                        </div>
                        <div className="contact">
                            <ContactForm
                                onSubmit={onSubmit}
                                buttonText={cmsElmt(
                                    "Button Text",
                                    "text",
                                    "CONTACT US"
                                )}
                            />
                        </div>
                    </div>
                </div>
            )}
        </CMSSection>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return {
        sendContactRequest: (data, requestType) =>
            dispatch(sendContactRequest(data, requestType)),
        reset: () => dispatch(reset("Contact_Form")),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactComponent);
