import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

//Actions
import { logout } from "../../../actions/login.action";

// Styles
import '../../../styles/components/admin/sidebar.scss';

const Sidebar = ({logout}) => {
    return (
        <nav className="sidebar">
            <ul className="navbar-nav">
                <li className="logo">
                    <Link to="/" className="nav-link">
                    <span className="link-text">{process.env.REACT_APP_PROJECT_NAME}</span>
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fad"
                        data-icon="angle-double-right"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        className="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                    >
                        <g className="fa-group">
                        <path
                            fill="currentColor"
                            d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                            className="fa-secondary"
                        ></path>
                        <path
                            fill="currentColor"
                            d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                            className="fa-primary"
                        ></path>
                        </g>
                    </svg>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/dashboard" className="nav-link">
                        <span className="link-text">Contacts</span>
                        <svg 
                            aria-hidden="true" 
                            focusable="false" 
                            data-prefix="fas" 
                            data-icon="sign-out-alt" 
                            className="svg-inline--fa fa-sign-out-alt fa-w-16" 
                            role="img" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 512 512"
                        >
                            <path 
                                fill="currentColor" 
                                d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                                className="fa-primary"
                            ></path>
                        </svg>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/dashboard/cms" className="nav-link">
                        <span className="link-text">CMS</span>
                        <svg 
                            aria-hidden="true" 
                            focusable="false" 
                            data-prefix="fas" 
                            data-icon="sign-out-alt" 
                            className="svg-inline--fa fa-sign-out-alt fa-w-16" 
                            role="img" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 512 512"
                        >
                            <path 
                                fill="currentColor" 
                                d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                                className="fa-primary"
                            ></path>
                        </svg>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/" className="nav-link" onClick={() => {logout()}}>
                        <span className="link-text">Log Out</span>
                        <svg 
                            aria-hidden="true" 
                            focusable="false" 
                            data-prefix="fas" 
                            data-icon="sign-out-alt" 
                            className="svg-inline--fa fa-sign-out-alt fa-w-16" 
                            role="img" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 512 512"
                        >
                            <path 
                                fill="currentColor" 
                                d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                                className="fa-primary"
                            ></path>
                        </svg>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
    logout,
})(Sidebar);
