import React, { Component } from "react";

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import styled from "styled-components";

const Handle = styled.div`
    display: Flex;
    justify-content: center;
    margin-right: 1px;
    padding: 0 5px;
    cursor: grab;
`;

const RowHandler = SortableHandle(() => <Handle className="handle">|</Handle>);

const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
});

export class SortableTableBody extends Component {
    render() {
        const { items, onSortEnd, TableRow } = this.props;

        const SortableItem = SortableElement((props) => (
            <tr key={`data_${props.index}`}>
                <TableRow {...props} />
                {this.props.includeHandler ? (
                    <td>
                        <RowHandler />
                    </td>
                ) : null}
            </tr>
        ));

        return (
            <SortableCont
                onSortEnd={onSortEnd}
                axis="y"
                lockAxis="y"
                lockToContainerEdges={true}
                lockOffset={["30%", "50%"]}
                helperClass="helperContainerClass"
                useDragHandle={true}
            >
                {items.map((value, index) => (
                    <SortableItem value={value} index={index} />
                ))}
            </SortableCont>
        );
    }
}
