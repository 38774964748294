import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Field,
    FieldArray,
    formValueSelector,
    reduxForm,
    change,
} from "redux-form";
import {
    dgInput,
    dgSelect,
    dgTextArea,
    dgInputSelect,
    dgUpload,
    dgToggle,
    required,
} from "dg-forms";
import { DgContainer, DgPicture } from "dg-utils";

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import styled from "styled-components";

// Actions
import {
    clearSections,
    updateCMSSection,
    updateCurrentCMSSection,
} from "../../../actions/cmsAction";
//Styles
import "../../../styles/components/admin/form.scss";

const Handle = styled.div`
    display: Flex;
    justify-content: center;
    margin-bottom: 25px;
    padding: 0 5px;
    cursor: grab;
    background-color: grey;
    align-content: center;
`;

const RowHandler = SortableHandle(() => <Handle className="handle">|</Handle>);

const TableRow = ({ value, indexValue, data, field }) => {
    const index = indexValue;
    return (
        <div key={`data_${index}`} className="field-group">
            {data &&
            data.data[index] &&
            data.data[index].fieldType === "image" ? (
                <React.Fragment>
                    <label>
                        {data &&
                        data.data[index] &&
                        data.data[index].name &&
                        data.data[index].name !== ""
                            ? data.data[index].name
                            : ""}
                    </label>
                    <div className="image-container">
                        <img src={data.data[index].value} alt="item " />
                    </div>
                    <Field
                        multiple={false}
                        accept="image/*"
                        name={`${field}.uploadFile`}
                        label="Value"
                        component={dgUpload}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <label>
                        {data &&
                        data.data[index] &&
                        data.data[index].name &&
                        data.data[index].name !== ""
                            ? data.data[index].name
                            : ""}
                    </label>
                    <Field
                        containerClass="col"
                        name={`${field}.value`}
                        type="text"
                        component={dgTextArea}
                        validate={[required]}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

function compare(a, b) {
    if (a.last_nom < b.last_nom) {
        return -1;
    }
    if (a.last_nom > b.last_nom) {
        return 1;
    }
    return 0;
}

const SortableItem = SortableElement((props) => (
    <div key={props.index}>
        <TableRow {...props} />
        <div>
            <RowHandler />
        </div>
    </div>
));

const renderData = ({ fields, data }) => (
    <React.Fragment>
        {fields.map((field, index) => (
            <SortableItem
                index={index}
                indexValue={index}
                data={data}
                field={field}
            />
        ))}
    </React.Fragment>
);
const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
});

class CMSForm extends Component {
    constructor(props) {
        super(props);
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    onSortEnd(sortData) {
        // Update the two indices
        let currentSection = this.props.cms.currentSection;
        currentSection.data[sortData.oldIndex].order = sortData.newIndex + 1;
        currentSection.data[sortData.newIndex].order = sortData.oldIndex + 1;
        // Update the database
        const form = new FormData();
        form.append("data", JSON.stringify(currentSection));
        this.props.updateCMSSection(currentSection._id, form);

        this.props.clearSections([]);
        this.props.updateCurrentCMSSection(currentSection);
        console.log("oldIndex", sortData);
    }

    render() {
        let { initialValues } = this.props;
        if (initialValues.data && initialValues.data.length > 0) {
            initialValues.data.sort((a, b) =>
                a.order > b.order ? 1 : b.order > a.order ? -1 : 0
            );
        }

        return (
            <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                <DgContainer
                    header={<h2>Basic Information</h2>}
                    content={
                        <React.Fragment>
                            <Field
                                disabled={true}
                                name="name"
                                type="text"
                                label="Name"
                                component={dgInput}
                                validate={[required]}
                            />
                            <Field
                                disabled={false}
                                name="order"
                                type="text"
                                label="Order"
                                component={dgInput}
                                validate={[required]}
                            />
                        </React.Fragment>
                    }
                />
                <DgContainer
                    header={<h2>Items</h2>}
                    content={
                        <SortableCont
                            onSortEnd={this.onSortEnd}
                            axis="y"
                            lockAxis="y"
                            lockToContainerEdges={true}
                            lockOffset={["30%", "50%"]}
                            helperClass="helperContainerClass"
                            useDragHandle={true}
                        >
                            <FieldArray
                                name="data"
                                data={initialValues}
                                component={renderData}
                            />
                        </SortableCont>
                    }
                />
                <div className="field-group">
                    <button
                        type="submit"
                        disabled={this.props.submitting}
                        className="btn-primary"
                    >
                        Save
                    </button>
                </div>
            </form>
        );
    }
}

CMSForm = reduxForm({
    form: "CMS_Form",
})(CMSForm);

const mapStateToProps = (state) => ({
    cms: state.cms,
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateCMSSection: (id, data) => dispatch(updateCMSSection(id, data)),
        updateCurrentCMSSection: (sectionData) =>
            dispatch(updateCurrentCMSSection(sectionData)),
        clearSections: (data) => dispatch(clearSections(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CMSForm);
