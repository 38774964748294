import React, { Component } from "react";
import { connect } from "react-redux";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";

// Assets
// import styles from '../../assets/files/mapStyles.json';

// Styles
import "../../styles/components/map/map.scss";

// Actions

// Components
// CMS
import CMSSection from "../../components/cms/cms";


class Map extends Component {
    render() {
        const centerPosition = {
            lat: 53.33940316274081,
            lng: -6.539115130480407,
        };

        const GoogleMapExample = withGoogleMap((props) => (
            <GoogleMap
                defaultCenter={centerPosition}
                defaultZoom={16}
                defaultOptions={{
                    disableDefaultUI: true, // disable default map UI
                }}
            >
                <Marker position={centerPosition} />
            </GoogleMap>
        ));

        return (
            <CMSSection name="Map Contact Details">
                {({ cmsElmt }) => (
                    <div className="map-container">
                        <div className="address-container">
                            <div className="address">
                                <h2>
                                    {cmsElmt(
                                        "Header",
                                        "text",
                                        "Where To Find Us"
                                    )}
                                </h2>
                                <p>
                                    {cmsElmt(
                                        "Address Line 1",
                                        "text",
                                        "Main St,"
                                    )}
                                    <br />
                                    {cmsElmt(
                                        "Address Line 2",
                                        "text",
                                        "Donaghcumper,"
                                    )}
                                    <br />
                                    {cmsElmt(
                                        "Address Line 3",
                                        "text",
                                        "Celbridge,"
                                    )}
                                    <br />
                                    {cmsElmt(
                                        "Address Line 4",
                                        "text",
                                        "Co. Kildare."
                                    )}
                                </p>
                                <p style={{ padding: "0" }}>
                                    <a href="tel:+353872994772">
                                        {cmsElmt(
                                            "Phone Details",
                                            "text",
                                            "Telephone: 087 299 4772"
                                        )}
                                    </a>{" "}
                                    <br />
                                </p>
                            </div>
                        </div>
                        <div className="map">
                            <GoogleMapExample
                                containerElement={
                                    <div className="google-map-container" />
                                }
                                mapElement={
                                    <div className="google-map-element" />
                                }
                            />
                        </div>
                    </div>
                )}
            </CMSSection>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Map);
