import { CREATE_CONTACT } from "./types";

export const sendContactRequest = (data, requestType) => async (dispatch) => {
    try {
        const contactCreateResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/contact/`,
            {
                method: requestType,
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const ResJSON = await contactCreateResponse.json();
        dispatch({
            type: CREATE_CONTACT,
            payload: ResJSON,
        });
        if (ResJSON.ok) {
            return ResJSON;
        } else {
            throw ResJSON;
        }
    } catch (error) {
        throw error;
    }
};
