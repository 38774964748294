import React from "react";
import { connect } from "react-redux";

//Components
import { ButtonSVG } from "../button/ButtonSVG";
import { Field, reduxForm } from "redux-form";
import { dgInput, required, email, phoneNumber, dgTextArea } from "dg-forms";

let Contact_Form = ({ handleSubmit, submitting, onSubmit, buttonText }) => {
    return (
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <Field
                name="name"
                type="text"
                component={dgInput}
                placeholder="Name"
                validate={[required]}
            />
            <Field
                name="phone"
                type="text"
                component={dgInput}
                placeholder="Number"
                validate={[phoneNumber]}
            />
            <Field
                name="email"
                type="text"
                component={dgInput}
                placeholder="Email"
                validate={[required, email]}
            />
            <Field
                name="message"
                type="text"
                component={dgTextArea}
                placeholder="Message"
                rows="4"
                validate={[required]}
            />
            <div className="button-container">
                <ButtonSVG disabled={submitting} type="submit">
                    {buttonText}
                </ButtonSVG>
            </div>
        </form>
    );
};

Contact_Form = reduxForm({ form: "Contact_Form" })(Contact_Form);

export const ContactForm = connect(null, {})(Contact_Form);
