import React from "react";
import { connect } from "react-redux";

//Actions
import { toggleMobileMenu } from "../../actions/toggleMobileMenu.js";

//Components
import { Hamburger } from "./hamburger/Hamburger";
import { NavLink } from "react-router-dom";
//import { RedDot } from "../redDot/RedDot";
import { Social } from "../social/Social";

//Styles
import "../../styles/components/navbar/mobileMenu.scss";

class MobileMenu extends React.Component {
    openStatus = () => {
        return this.props.open ? "open" : "";
    };

    render() {
        return (
            <div className="mobile-menu">
                <Hamburger
                    openStatus={this.openStatus}
                    toggleMobileMenu={this.props.toggleMobileMenu}
                />
                <div className={`mobile-links-container ${this.openStatus()}`}>
                    <div className="mobile-links-centering-container">
                        <div className="navlink-div">
                            <ul className="navlinks-mobile">
                                <li>
                                    <NavLink
                                        to="/"
                                        onClick={() => {this.props.scrollToAbout();this.props.toggleMobileMenu()}}
                                    >
                                        ABOUT US
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/"
                                        onClick={() => {this.props.scrollToHours();this.props.toggleMobileMenu()}}
                                    >
                                        HOURS OF OPERATION
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/"
                                        onClick={() => {this.props.scrollToPricing();this.props.toggleMobileMenu()}}
                                    >
                                        PRICING
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/"
                                        onClick={() => {this.props.scrollToReviews();this.props.toggleMobileMenu()}}
                                    >
                                        REVIEWS
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/"
                                        onClick={() => {this.props.scrollToContact();this.props.toggleMobileMenu()}}
                                    >
                                        CONTACT US
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mobile-contact-icons-container">
                        <Social altTheme={"-primary"} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    open: state.mobile.open,
});

const mapDispatchToProps = (dispatch) => ({
    toggleMobileMenu: () => dispatch(toggleMobileMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
