import {
    FETCH_CONTACTS_REQUEST,
    FETCH_CONTACTS_SUCCESS,
    FETCH_CONTACTS_FAILURE,
} from "./types";

export const fetchContacts = (page, page_size, filters) => async (dispatch) => {
    //Used to indicate to the user that a request is underway
    dispatch({
        type: FETCH_CONTACTS_REQUEST,
    });
    try {
        //Establishing pagination and type query url string
        let query = ``;
        const allContacts = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/contact?page=${page}&page_size=${page_size}${query}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                },
            }
        );
        const allContactsJSON = await allContacts.json();
        dispatch({
            type: FETCH_CONTACTS_SUCCESS,
            payload: allContactsJSON,
        });
        return allContactsJSON
    } catch (error) {
        //If any errors, dispatch the failure action recording the associated error.
        dispatch({
            type: FETCH_CONTACTS_FAILURE,
            payload: error,
        });
    }
};
