import React from 'react';

//Styles
import "../../styles/components/blocks/PageSpacer.scss";

export const PageSpacer = () => {
    return (
        <div className="page-spacer" />
    )
}
