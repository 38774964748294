import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { TestimonialGoogle } from "dg-testimonials";
import { DgLazy } from "dg-utils";

//Components
import { BackgroundImage } from "../blocks/backgroundImage";

// Styles
import "../../styles/components/testimonials/testimonials.scss";
import "dg-testimonials/dist/index.css";

//Actions
import { getReviews } from "../../actions/reviews.action";

//Assets
import { testimonials } from "../../assets/files/testimonials";
import woodImage from "../../assets/images/wood-background-texture-1.jpg";
import woodImageWebP from "../../assets/images/wood-background-texture-1.webp";
import woodMobile from "../../assets/images/mobile/wood-background-texture-1.jpg";
import woodMobileWebP from "../../assets/images/mobile/wood-background-texture-1.webp";
import arrowLeft from "../../assets/icons/arrow-left.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";

// CMS
import CMSSection from "../../components/cms/cms";

class Testimonials extends Component {
    componentDidMount() {
        this.props
            .getReviews()
            .then((res) => {
                console.log("getting reviews");
            })
            .catch((res) => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        const { cms } = this.props;
        return (
            <CMSSection name="Testimonials">
                {({ cmsElmt }) => (
                    <div>
                        <BackgroundImage
                            image={cmsElmt(
                                "Background Image (Desktop)",
                                "image",
                                `${process.env.REACT_APP_CMS_PATH}/images/wood-background-texture-1.jpg`
                            )}
                            imageWebP={cmsElmt(
                                "Background Image (Desktop - webp)",
                                "image",
                                `${process.env.REACT_APP_CMS_PATH}/images/wood-background-texture-1.webp`
                            )}
                            mobile={cmsElmt(
                                "Background Image (Mobile)",
                                "image",
                                `${process.env.REACT_APP_CMS_PATH}/images/mobile/wood-background-texture-1.jpg`
                            )}
                            mobileWebP={cmsElmt(
                                "Background Image (Mobile - webp)",
                                "image",
                                `${process.env.REACT_APP_CMS_PATH}/images/mobile/wood-background-texture-1.webp`
                            )}
                        />
                        <div
                            ref={this.props.innerRef}
                            className="testimonials-container clamp"
                        >
                            <TestimonialGoogle
                                arrowLeft={arrowLeft}
                                arrowRight={arrowRight}
                                header={
                                    <h1 className="heading-primary">
                                        {cmsElmt("Header", "text", "REVIEWS")}
                                    </h1>
                                }
                                hideStars
                                profileImageHide
                                testimonials={
                                    this.props.reviews &&
                                    this.props.reviews.data &&
                                    this.props.reviews.data.reviews &&
                                    this.props.reviews.data.reviews.length > 0
                                        ? this.props.reviews.data.reviews.filter(
                                              (review) =>
                                                  review.comment &&
                                                  review.comment !== ""
                                          )
                                        : testimonials.filter(
                                              (review) =>
                                                  review.comment &&
                                                  review.comment !== ""
                                          )
                                }
                            />
                        </div>
                    </div>
                )}
            </CMSSection>
        );
    }
}

const mapStateToProps = (state) => ({
    reviews: state.reviews.reviews,
});

export default connect(mapStateToProps, {
    getReviews,
})(Testimonials);
