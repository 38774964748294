import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import JWT from 'jsonwebtoken';

class ProtectedRoute extends Component {
    
    isAuthenticated() {
        let AuthToken = 'myprivatekey';
        let isAuthenticated = false;
        let projectName = `${process.env.REACT_APP_PROJECT_NAME}`;
        try {
            if(localStorage.getItem(`${projectName}::access_token`)) {
                let token = localStorage.getItem(`${projectName}::access_token`);
                let user = JWT.verify(token, AuthToken);
                isAuthenticated = user.isActive;
            }
        } catch (error) {
            console.log(error);
            return isAuthenticated;
        }
        
        return isAuthenticated;
    }

    render() {
        const { component: Component, ...props } = this.props
    
        return (
            <Route 
            {...props} 
            render={props => (
                this.isAuthenticated() ?
                <Component {...props} /> :
                <Redirect to='/' />
            )} 
            />
        )
    }
}

export default connect(null, {})(ProtectedRoute);