import React from "react";

//Components
import { BackgroundImage } from "./backgroundImage";
import { ButtonSVG } from "../button/ButtonSVG";

//Assets
import woodImage from "../../assets/images/wood-background-texture-1.jpg";
import woodImageWebP from "../../assets/images/wood-background-texture-1.webp";
import woodMobile from "../../assets/images/mobile/wood-background-texture-1.jpg";
import woodMobileWebP from "../../assets/images/mobile/wood-background-texture-1.webp";

//Styles
import "../../styles/components/blocks/ParagraphVertical.scss";

// CMS
import CMSSection from "../../components/cms/cms";

export const ParagraphVertical = ({
    innerRef,
    title,
    body,
    alt,
    button,
    linkTo,
}) => {
    return (
        <CMSSection name="Background Image">
            {({ cmsElmt }) => (
                <div ref={innerRef} className="vertical-para-container">
                    <BackgroundImage
                        image={cmsElmt(
                            "Background Image (Desktop)",
                            "image",
                            `${process.env.REACT_APP_CMS_PATH}/images/wood-background-texture-1.jpg`
                        )}
                        imageWebP={cmsElmt(
                            "Background Image (Desktop - webp)",
                            "image",
                            `${process.env.REACT_APP_CMS_PATH}/images/wood-background-texture-1.webp`
                        )}
                        mobile={cmsElmt(
                            "Background Image (Mobile)",
                            "image",
                            `${process.env.REACT_APP_CMS_PATH}/images/mobile/wood-background-texture-1.jpg`
                        )}
                        mobileWebP={cmsElmt(
                            "Background Image (Mobile - webp)",
                            "image",
                            `${process.env.REACT_APP_CMS_PATH}/images/mobile/wood-background-texture-1.webp`
                        )}
                    />
                    <div className="content-container">
                        <div className="top-container">
                            <div className="title-container">
                                <h2>{title}</h2>
                            </div>
                        </div>
                        <div className="body-container">
                            {body &&
                                body.map((paragraph, index) => {
                                    return <p key={index}>{paragraph}</p>;
                                })}
                            {button && (
                                <a href={linkTo}>
                                    <ButtonSVG>View More</ButtonSVG>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </CMSSection>
    );
};
