import React from "react";

//Components
import { LoginComponent } from "../../../components/login/login.component";

export const LoginPage = () => {
    return (
        <div className="container-alt">
            <main>
                <LoginComponent />
            </main>
        </div>
    );
};
