import { UPDATE_IS_MOBILE_SITE  } from '../actions/types';

//Include mobile dimensions breakpoint here
const initialValues = {
    isMobileSite: false,
    mobileBreakpoint: 1024,
}

export default function (state = initialValues, action) {
    switch(action.type) {
        case UPDATE_IS_MOBILE_SITE: 
            return {
                ...state,
                isMobileSite: action.payload,
            }
        default:
            return state;
    }
}

