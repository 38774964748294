import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

// Actions
import { clearSections, getCMSById, updateCMSSection } from "../../../actions/cmsAction";

// Components
import CMSForm from "./CMS.form";
import CMSList from "./CMSList";
import Sidebar from "../sidebar/sidebar.admin.component";

class EditCMS extends Component {
    constructor(props) {
        super(props);

        this.state = {
            section: undefined,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props.getCMSById(params[3]).catch((res) => {});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
        this.props.clearSections([]);

            let urlParams = this.props.location.pathname;
            let params = urlParams.split("/");

            this.props.getCMSById(params[3]).catch((res) => {});
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        this.props.clearSections([]);
    }

    onSubmit(data) {
        const form = new FormData();

        let updatedId = [];
        if (data.data.length > 0) {
            for (let i = 0; i < data.data.length; i++) {
                let item = data.data[i];

                if (item.fieldType === "image" && typeof item.uploadFile === "object") {
                    form.append("section_images", item.uploadFile[0]);
                    updatedId.push(item._id);
                    delete item.uploadFile;
                }
            }
        }
        data.updatedId = updatedId;
        form.append("data", JSON.stringify(data));

        this.props
            .updateCMSSection(data._id, form)
            .then((res) => {
                this.componentDidMount();
                Swal.fire("Updated Successfully!", res.message, "success");
            })
            .catch((res) => {
                Swal.fire("Error", res.message, "error");
            });
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="List-Container">
                        <div className="header-container">
                            <div className="list-header">
                                <h1>CMS Management</h1>
                            </div>
                        </div>
                        <CMSForm
                            onSubmit={this.onSubmit}
                            initialValues={this.props.cms}
                            enableReinitialize={true}
                        />
                        {this.props.cms && this.props.cms._id && this.props.cms._id !== "" ? (
                            <CMSList nested={true} criteria={{ parent: this.props.cms._id }} />
                        ) : (
                            ""
                        )}
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    form: state.form,
    cms: state.cms.currentSection,
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateCMSSection: (id, data) => dispatch(updateCMSSection(id, data)),
        clearSections: (data) => dispatch(clearSections(data)),
        getCMSById: (id) => dispatch(getCMSById(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCMS);
