import { GET_REVIEWS_FAILED, GET_REVIEWS_SUCCESS } from "./types";

export const getReviews = (page, page_size) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/googleReviews/getReviews/`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GET_REVIEWS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GET_REVIEWS_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

