import React from "react";

//Styles
import "../../styles/components/card/card.scss";

export const Card = ({title, children}) => {
    return (
        <div className="card-container">
            <div className="card-title">
                <h2>{title}</h2>
            </div>
            <div className="card-body">{children}</div>
        </div>
    );
};
