import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { dgInput, required, email } from 'dg-forms';

const LoginForm = props => {
  const { handleSubmit, pristine, submitting } = props
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        type="email"
        component={dgInput}
        containerClass="field-group"
        inputContainerClass="form-input-container"
        label="Email address"
        labelClass="required"
        validate={[required, email]}
      /> 
      <Field
        name="password"
        type="password"
        component={dgInput}
        label="Password"
        labelClass="required"
        validate={[required]}
      />
      <button 
        type="submit" 
        className="btn-primary" 
        disabled={pristine || submitting}
      >
        Login
      </button>
    </form>
  )
}

export default reduxForm({
  form: 'LoginForm' // a unique identifier for this form
})(LoginForm)
