import React from "react";

//Components
import Sidebar from "../../../components/admin/sidebar/sidebar.admin.component";
import ContactListContainer from "../../../components/contact/ContactListContainer.js";

export const Dashboard = () => {
    return (
        <div className="container-alt">
            <Sidebar />
            <main>
                <ContactListContainer />
            </main>
        </div>
    );
};
