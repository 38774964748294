import React from "react";
import { connect } from "react-redux";

//Components
import { BackgroundImage } from "../blocks/backgroundImage";

//Assets
import hoursImage from "../../assets/images/hours-of-operation-img-1.jpg";
import hoursImageWebP from "../../assets/images/hours-of-operation-img-1.webp";
import hoursImageMobile from "../../assets/images/mobile/hours-of-operation-img-1.jpg";
import hoursImageWebPMobile from "../../assets/images/mobile/hours-of-operation-img-1.webp";

//Styles
import "../../styles/components/Tables/FeesTable.scss";

// CMS
import CMSSection from "../../components/cms/cms";

export const FeesTableComponent = ({ innerRef, cms }) => {
    return (
        <CMSSection name="Pricing">
            {({ cmsElmt }) => (
                <div className="fees-container" ref={innerRef}>
                    <BackgroundImage
                        image={hoursImage}
                        imageWebP={hoursImageWebP}
                        mobile={hoursImageMobile}
                        mobileWebP={hoursImageWebPMobile}
                    />
                    <div className="content-container">
                        <div className="title-container">
                            <h2>{cmsElmt("Header", "text", "PRICING")}</h2>
                        </div>
                        <div className="fees-table-container">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="left">
                                            {cmsElmt("Service 1 Name", "text", "SKIN FADE")}
                                        </td>
                                        <td className="right">
                                            {cmsElmt("Service 1 Price", "text", "€13")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            {cmsElmt("Service 2 Name", "text", "MENS")}
                                        </td>
                                        <td className="right">
                                            {cmsElmt("Service 2 Price", "text", "€13")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            {cmsElmt("Service 3 Name", "text", "KIDS 12 & UNDER")}
                                        </td>
                                        <td className="right">
                                            {cmsElmt("Service 3 Price", "text", "€10")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            {cmsElmt("Service 4 Name", "text", "OAPs")}
                                        </td>
                                        <td className="right">
                                            {cmsElmt("Service 4 Price", "text", "€10")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            {cmsElmt("Service 5 Name", "text", "FATHER & SON")}
                                        </td>
                                        <td className="right">
                                            {cmsElmt("Service 5 Price", "text", "€22")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            {cmsElmt("Service 6 Name", "text", "LADIES")}
                                        </td>
                                        <td className="right">
                                            {cmsElmt("Service 6 Price", "text", "€18-€22")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            {cmsElmt(
                                                "Service 7 Name",
                                                "text",
                                                "TEEN GIRLS DRY CUT"
                                            )}
                                        </td>
                                        <td className="right">
                                            {cmsElmt("Service 7 Price", "text", "€18")}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="button-container">
                            <a href="https://www.fresha.com/providers/the-liffey-barbers-asbdu35x?pId=480075">
                                <button className="btn-primary">
                                    {cmsElmt("Button Text", "text", "BOOK NOW")}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </CMSSection>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, getState) => ({});

export const FeesTable = connect(mapStateToProps, mapDispatchToProps)(FeesTableComponent);
