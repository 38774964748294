import { 
    LOGIN_SUCCESS, 
    LOGIN_FAILED, 
    LOGOUT 
} from './types';
import history from '../history';

export const login = (userCredentials) => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth/login`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(userCredentials)
        })
        .then(res => res.json())
        .then(post => {
            if(!post.ok) {
                dispatch({
                    type: LOGIN_FAILED,
                    payload: post
                });

                reject(post);
            } else {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: post
                });

                resolve(post);
            }
        });
    });
};

export const logout = () => dispatch => {
    let projectName = `${process.env.REACT_APP_PROJECT_NAME}`;
    localStorage.clear(`${projectName}::access_token`)

    dispatch({
        type: LOGOUT
    });

    history.push('/');
};

export function getAccessToken() {
    let token = localStorage.getItem(`${process.env.REACT_APP_PROJECT_NAME}::access_token`);
    return token;
}
