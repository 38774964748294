import { TOGGLE_MOBILE_MENU } from '../actions/types';

const initialValues = {
    open: false,
}

export default function (state = initialValues, action) {
    switch(action.type) {
        case TOGGLE_MOBILE_MENU:
            return {
                ...state,
                open: !state.open,
            }
        default:
            return state;
    }
}
