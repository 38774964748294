import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Pagination } from "dg-pagination";

//Actions
import { fetchContacts } from "../../actions/fetchContactsAction";

//Components
import { ContactList } from "./ContactList.js";

class ContactListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            page_size: 10,
            total_pages: 0,
            total_items: 0,
        };
        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
    }

    paginate(page, page_size) {
        this.props
            .fetchContacts(page, page_size)
            .then((res) => {
                this.setState({
                    page: res.page,
                    page_size: res.page_size,
                    total_pages: res.total_pages,
                    total_items: res.total_items,
                });
            })
            .catch((res) => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        return (
            <>
                <ContactList
                    contacts={this.props.contacts}
                    isfetching={this.props.isFetching}
                />
                <Pagination
                    type="Standard"
                    paginate={this.paginate}
                    page_size={this.state.page_size}
                    page={this.state.page}
                    total_pages={this.state.total_pages}
                    total_items={this.state.total_items}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    contacts: state.contacts.contacts,
    isFetching: state.contacts.isFetching,
});

//See what happens with direct substitution into below. HOFs in getAllContacts may need rewriting.
const mapDispatchToProps = (dispatch) => ({
    fetchContacts: (page, page_size, filters) =>
        dispatch(fetchContacts(page, page_size, filters)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactListContainer);
