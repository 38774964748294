//Types
import {
    FETCH_CONTACTS_REQUEST,
    FETCH_CONTACTS_SUCCESS,
    FETCH_CONTACTS_FAILURE,
} from "../actions/types";

const initialState = {
    contacts: [],
    isFetching: false,
    message: "",
    ok: false,
    errors: [],
};

//Success/Failure cases written identically for testing, can be made more DRY later.
export function fetchContactsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONTACTS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_CONTACTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                contacts: action.payload.contactList,
                message: action.payload.message,
                ok: action.payload.ok,
                errors: action.payload.errors,
            };
        case FETCH_CONTACTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                contacts: action.payload.contactList,
                message: action.payload.message,
                ok: action.payload.ok,
                errors: action.payload.errors,
            };
        default:
            return state;
    }
}

