import React from "react";

//Components
import { connect } from "react-redux";
import { DgPicture } from "dg-utils";
import { HeaderSVG } from "../headerSVG/HeaderSVG";

//Actions
import { updateHeaderPosition } from "../../actions/updateHeaderPosition";

//Styles
import "../../styles/components/header/header.scss";

class Header extends React.Component {
    //Get header component height to decide when NavBar should change color theme
    handleResize = () => {
        let headerHeight = document.getElementById("header").clientHeight;
        this.props.updateHeaderPosition(headerHeight);
    };

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        this.props.updateHeaderPosition(0);
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        return (
            <div className="header-container">
                <div className="header">
                    <div className="header-image-container" id="header">
                        {this.props.icon ? <HeaderSVG /> : ''}
                        <DgPicture>
                            <source
                                media="(max-width: 1023px)"
                                srcSet={this.props.mobileWebP}
                                type="image/webp"
                            />
                            <source
                                media="(max-width: 1023px)"
                                srcSet={this.props.mobile}
                                type="image/png"
                            />
                            <source
                                srcSet={this.props.desktopWebP}
                                type="image/webp"
                            />
                            <img src={this.props.desktop} alt="Woman using massage balls during neck muscle therapy" />
                        </DgPicture>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    updateHeaderPosition,
})(Header);
