// import React from "react";
export const testimonials = [     
  { 
    reviewer: { 
      displayName: `Kathriona Dunne`,
    },
    starRating: 'FIVE',    
    comment: `Love this barbers my son won't let anybody else cut his hair. The girls are brill.`,
    createTime: '2020-08-27T17:24:40.778Z'
  },
  {
    reviewer: { 
      displayName: `David Condron`,
    },
    starRating: 'FIVE',    
    comment: `Great cut. Very friendly staff.`,
    createTime: '2020-08-27T17:24:40.778Z'      
  },
  {
    reviewer: {
      displayName: `Kelly Maxwell`,
    },
    starRating: 'FIVE',   
    comment: `Quick and easy dry ladies trim. Husband got a great cut too. Clean and fast. Hassle free. Good location!`,
    createTime: '2020-08-27T17:24:40.778Z'
  },
  { 
    reviewer: { 
      displayName: `Brendan Cahill`,
    },
    starRating: 'FOUR',    
    comment: `Great haircut here from friendly staff. Good social distance in the saloon and haircuts are very reasonable priced.`,
    createTime: '2020-08-27T17:24:40.778Z'
  },
  {
    reviewer: { 
      displayName: `Avis O Donovan`,
    },
    starRating: 'FIVE',    
    comment: `Got my hair done here on Friday as my regular hairdresser was booked up. I was thrilled with my hair and the staff are so so friendly. Definitely recommend this place.`,
    createTime: '2020-08-27T17:24:40.778Z'      
  },
  {
    reviewer: {
      displayName: `Emmet O'Neill`,
    },
    starRating: 'FIVE',   
    comment: `Lovely staff and great at what they do 👍`,
    createTime: '2020-08-27T17:24:40.778Z'
  }
]
