import React from "react";
import { connect } from "react-redux";


//Components
//import { PageSpacer } from "../components/blocks/PageSpacer";
import NavBar from "../components/navbar/NavBar";
import Header from "../components/header/Header";
import { ParagraphVertical } from "../components/blocks/ParagraphVertical.js";
import { FeesTable } from "../components/Tables/FeesTable";
import { HoursTable } from "../components/Tables/HoursTable";
//import { Appointment } from "../components/appointment/Appointment.js";
//import Location from "../components/blocks/Location";
import Contact from "../components/contact/ContactComponent";
import Testimonials from "../components/testimonials/testimonials.js";
import { Footer } from "../components/footer/Footer";
import { GalleryContainer } from "../components/gallery/galleryContainer";

//Assets
//import desktopHeaderImageWebP from "../assets/images/hero-img-1.webp";
//import mobileHeaderImageWebP from "../assets/images/mobile/hero-img-1.webp";
//import desktopHeaderImage from "../assets/images/hero-img-1.jpg";
//import mobileHeaderImage from "../assets/images/mobile/hero-img-1.jpg";

//Block content
import { aboutUs } from "../assets/copy/home";

// CMS
import CMSSection from "../components/cms/cms";

class HomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.aboutRef = React.createRef();
        this.hoursRef = React.createRef();
        this.pricesRef = React.createRef();
        this.testimonialRef = React.createRef();
        this.contactRef = React.createRef();
    }

    //This aint great
    scrollToAbout = () =>
        this.aboutRef.current.scrollIntoView({ behavior: "smooth" });
    scrollToHours = () =>
        this.hoursRef.current.scrollIntoView({ behavior: "smooth" });
    scrollToPricing = () =>
        this.pricesRef.current.scrollIntoView({ behavior: "smooth" });
    scrollToReviews = () =>
        this.testimonialRef.current.scrollIntoView({ behavior: "smooth" });
    scrollToContact = () =>
        this.contactRef.current.scrollIntoView({ behavior: "smooth" });

    render() {
        const { cms } = this.props;

        return (
            <div className="home-page-container">
                <NavBar
                    scrollToAbout={this.scrollToAbout}
                    scrollToHours={this.scrollToHours}
                    scrollToPricing={this.scrollToPricing}
                    scrollToReviews={this.scrollToReviews}
                    scrollToContact={this.scrollToContact}
                />
                <CMSSection name="Header">
                    {({ cmsElmt }) => (
                        <Header
                            desktop={cmsElmt(
                                "Background Image (Desktop)",
                                "image",
                                `${process.env.REACT_APP_CMS_PATH}/images/hero-img-1.jpg`
                            )}
                            desktopWebP={cmsElmt(
                                "Background Image (Desktop - webp)",
                                "image",
                                `${process.env.REACT_APP_CMS_PATH}/images/hero-img-1.webp`
                            )}
                            mobile={cmsElmt(
                                "Background Image (Mobile)",
                                "image",
                                `${process.env.REACT_APP_CMS_PATH}/images/mobile/hero-img-1.jpg`
                            )}
                            mobileWebP={cmsElmt(
                                "Background Image (Mobile - webp)",
                                "image",
                                `${process.env.REACT_APP_CMS_PATH}/images/mobile/hero-img-1.webp`
                            )}
                            icon
                        />
                    )}
                </CMSSection>
                <CMSSection name="About Us">
                    {({ cmsElmt }) => (
                        <ParagraphVertical
                            innerRef={this.aboutRef}
                            title={cmsElmt("Header", "text", aboutUs.title)}
                            body={[
                                cmsElmt("Body Text", "text", aboutUs.body[0]),
                            ]}
                        />
                    )}
                </CMSSection>
                <FeesTable innerRef={this.pricesRef} />
                <HoursTable innerRef={this.hoursRef} />
                <GalleryContainer />
                <Testimonials innerRef={this.testimonialRef} />
                <Contact innerRef={this.contactRef} />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, getState) => ({});

export const Home = connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
