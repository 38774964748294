import React, { Component } from "react";
import { connect } from "react-redux";
//import Swal from "sweetalert2";
import history from "../../../history";
import { Pagination } from "dg-pagination";

import { SortableTableBody } from "./SortableTable";

// Styles
import "../../../styles/components/admin/list.scss";
import "../../../styles/components/admin/admin.scss";

// Actions
import { fetchCMSData, updateCMSSection } from "../../../actions/cmsAction";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";
import ListOptions from "../listOptions/listOptions";

function compare(a, b) {
    if (a.last_nom < b.last_nom) {
        return -1;
    }
    if (a.last_nom > b.last_nom) {
        return 1;
    }
    return 0;
}

const TableRow = ({ value, index }) => {
    return (
        // Create the row objects
        <React.Fragment>
            <td onClick={() => history.push(`/dashboard/cms/${value._id}`)}>
                {value.name}
            </td>
            <td onClick={() => history.push(`/dashboard/cms/${value._id}`)}>
                {value.level}
            </td>
            <td onClick={() => history.push(`/dashboard/cms/${value._id}`)}>
                {new Date(value.createdAt).toLocaleDateString()}{" "}
                {new Date(value.createdAt).toLocaleTimeString()}
            </td>
            <td>
                <ListOptions
                    onEdit={() => history.push(`/dashboard/cms/${value._id}`)}
                />
            </td>
        </React.Fragment>
    );
};

class CMSList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 10,
            total_pages: 0,
            total_items: 0,
            isLoading: false,
        };

        this.paginate = this.paginate.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
    }

    paginate(page, page_size) {
        this.props.fetchCMSData(this.props.criteria || { level: 1 });
    }

    onSortEnd(sortData) {
        // Uppate the first index
        const form_1 = new FormData();
        form_1.append(
            "data",
            JSON.stringify({
                ...this.props.cms.sections[sortData.oldIndex],
                order: sortData.newIndex + 1,
            })
        );
        this.props.updateCMSSection(
            this.props.cms.sections[sortData.oldIndex]._id,
            form_1
        );

        // Uppate the second index
        const form_2 = new FormData();
        form_2.append(
            "data",
            JSON.stringify({
                ...this.props.cms.sections[sortData.newIndex],
                order: sortData.oldIndex + 1,
            })
        );
        this.props.updateCMSSection(
            this.props.cms.sections[sortData.newIndex]._id,
            form_2
        );

        console.log("oldIndex", sortData);
    }

    render() {
        let items = [];
        if (
            this.props.cms &&
            this.props.cms.sections &&
            this.props.cms.sections.length > 0
        ) {
            // Sort the data first by order
            this.props.cms.sections.sort((a, b) =>
                a.order > b.order ? 1 : b.order > a.order ? -1 : 0
            );

            items = this.props.cms.sections;
        } else {
            items = [];
            {
                /*rows = [
                <tr>
                    <td colSpan="5">
                        {this.props.nested
                            ? "No nested sections exist"
                            : "No CMS sections exist"}
                    </td>
                </tr>,
            ];*/
            }
        }

        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="List-Container">
                        <div className="header-container">
                            <div className="list-header">
                                <h1>
                                    {this.props.nested
                                        ? "Nested CMS Sections"
                                        : "CMS Management"}
                                </h1>
                            </div>
                        </div>
                        <div className="list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Level</th>
                                        <th>Created On</th>
                                        <th>Actions</th>
                                        <th>Reorder</th>
                                    </tr>
                                </thead>
                                <SortableTableBody
                                    onSortEnd={this.onSortEnd}
                                    items={items}
                                    TableRow={TableRow}
                                    includeHandler
                                />
                            </table>
                            <Pagination
                                type="Standard"
                                paginate={this.paginate}
                                page_size={this.state.page_size}
                                page={this.state.page}
                                total_pages={this.state.total_pages}
                                total_items={this.state.total_items}
                            />
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cms: state.cms,
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCMSData: (criteria) => dispatch(fetchCMSData(criteria)),
        updateCMSSection: (id, data) => dispatch(updateCMSSection(id, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CMSList);
