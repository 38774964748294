import React from "react";
import { connect } from "react-redux";

//Components
import Gallery from "./gallery";
import { BackgroundImage } from "../blocks/backgroundImage";

//Assets
//import galleryImage from "../../assets/images/gallery-img-1.jpg";
//import galleryImageWebP from "../../assets/images/gallery-img-1.webp";
//import galleryMobile from "../../assets/images/mobile/gallery-img-1.jpg";
//import galleryMobileWebP from "../../assets/images/mobile/gallery-img-1.webp";

//Styles
import "../../styles/components/gallery/galleryContainer.scss";

// CMS
import CMSSection from "../../components/cms/cms";

//Using setInnerHTML to place line break in specific place.
//No user inputs to sanitise.
export const GalleryContainerComponent = ({
    title,
    subtitle,
    contents,
    cms,
}) => {
    return (
        <CMSSection name="Gallery">
            {({ cmsElmt }) => (
                <div className="gallery-container-container">
                    <CMSSection name="Background Image">
                        {({ cmsElmt }) => (
                            <BackgroundImage
                                image={cmsElmt(
                                    "Background Image (Desktop)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/gallery-img-1.jpg`
                                )}
                                imageWebP={cmsElmt(
                                    "Background Image (Desktop - webp)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/gallery-img-1.webp`
                                )}
                                mobile={cmsElmt(
                                    "Background Image (Mobile)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/mobile/gallery-img-1.jpg`
                                )}
                                mobileWebP={cmsElmt(
                                    "Background Image (Mobile - webp)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/mobile/gallery-img-1.webp`
                                )}
                            />
                        )}
                    </CMSSection>
                    <div className="content-container">
                        <div className="title-container">
                            <h2>{cmsElmt("Header", "text", "GALLERY")}</h2>
                        </div>
                        <CMSSection name="Gallery Images">
                            {({ cmsElmt }) => (
                                <Gallery
                                    slides={[
                                        {
                                            imgSrc: cmsElmt(
                                                "Slide 1",
                                                "image",
                                                `${process.env.REACT_APP_CMS_PATH}/images/gallery/hair-1.jpg`
                                            ),
                                            imgAlt: "",
                                        },
                                        {
                                            imgSrc: cmsElmt(
                                                "Slide 2",
                                                "image",
                                                `${process.env.REACT_APP_CMS_PATH}/images/gallery/hair-2.jpg`
                                            ),
                                            imgAlt: "",
                                        },
                                        {
                                            imgSrc: cmsElmt(
                                                "Slide 3",
                                                "image",
                                                `${process.env.REACT_APP_CMS_PATH}/images/gallery/hair-3.jpg`
                                            ),
                                            imgAlt: "",
                                        },
                                        {
                                            imgSrc: cmsElmt(
                                                "Slide 4",
                                                "image",
                                                `${process.env.REACT_APP_CMS_PATH}/images/gallery/hair-4.jpg`
                                            ),
                                            imgAlt: "",
                                        },
                                        {
                                            imgSrc: cmsElmt(
                                                "Slide 5",
                                                "image",
                                                `${process.env.REACT_APP_CMS_PATH}/images/gallery/hair-5.jpg`
                                            ),
                                            imgAlt: "",
                                        },
                                        {
                                            imgSrc: cmsElmt(
                                                "Slide 6",
                                                "image",
                                                `${process.env.REACT_APP_CMS_PATH}/images/gallery/hair-6.jpg`
                                            ),
                                            imgAlt: "",
                                        },
                                    ]}
                                />
                            )}
                        </CMSSection>
                    </div>
                </div>
            )}
        </CMSSection>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, getState) => ({});

export const GalleryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GalleryContainerComponent);
