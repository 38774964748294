import React from "react";
import { connect } from "react-redux";

//Components
import { BackgroundImage } from "../blocks/backgroundImage";
import Map from "../map/map";

//Assets
import woodImage from "../../assets/images/wood-background-texture-1.jpg";
import woodImageWebP from "../../assets/images/wood-background-texture-1.webp";
import woodMobile from "../../assets/images/mobile/wood-background-texture-1.jpg";
import woodMobileWebP from "../../assets/images/mobile/wood-background-texture-1.webp";

//Styles
import "../../styles/components/Tables/FeesTable.scss";

// CMS
import CMSSection from "../../components/cms/cms";

export const HoursTableComponent = ({ innerRef, cms }) => {
    return (
        <CMSSection name="Operating Hours">
            {({ cmsElmt }) => (
                <div className="container">
                    <CMSSection name="Background Image">
                        {({ cmsElmt }) => (
                            <BackgroundImage
                                image={cmsElmt(
                                    "Background Image (Desktop)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/wood-background-texture-1.jpg`
                                )}
                                imageWebP={cmsElmt(
                                    "Background Image (Desktop - webp)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/wood-background-texture-1.webp`
                                )}
                                mobile={cmsElmt(
                                    "Background Image (Mobile)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/mobile/wood-background-texture-1.jpg`
                                )}
                                mobileWebP={cmsElmt(
                                    "Background Image (Mobile - webp)",
                                    "image",
                                    `${process.env.REACT_APP_CMS_PATH}/images/mobile/wood-background-texture-1.webp`
                                )}
                            />
                        )}
                    </CMSSection>
                    <div className="inner-column">
                        <div className="fees-container" ref={innerRef}>
                            <div className="content-container">
                                <div className="title-container">
                                    <h2>
                                        {cmsElmt(
                                            "Header",
                                            "text",
                                            "HOURS OF OPERATION"
                                        )}
                                    </h2>
                                </div>
                                <div className="fees-table-container">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="left">
                                                    {cmsElmt(
                                                        "Timeslot 1 Name",
                                                        "text",
                                                        "MONDAY"
                                                    )}
                                                </td>
                                                <td className="right">
                                                    {cmsElmt(
                                                        "Timeslot 1 Time",
                                                        "text",
                                                        "9AM - 6PM"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left">
                                                    {cmsElmt(
                                                        "Timeslot 2 Name",
                                                        "text",
                                                        "TUESDAY"
                                                    )}
                                                </td>
                                                <td className="right">
                                                    {cmsElmt(
                                                        "Timeslot 2 Time",
                                                        "text",
                                                        "9AM - 6PM"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left">
                                                    {cmsElmt(
                                                        "Timeslot 3 Name",
                                                        "text",
                                                        "WEDNESDAY"
                                                    )}
                                                </td>
                                                <td className="right">
                                                    {cmsElmt(
                                                        "Timeslot 3 Time",
                                                        "text",
                                                        "9AM - 6PM"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left">
                                                    {cmsElmt(
                                                        "Timeslot 4 Name",
                                                        "text",
                                                        "THURSDAY"
                                                    )}
                                                </td>
                                                <td className="right">
                                                    {cmsElmt(
                                                        "Timeslot 4 Time",
                                                        "text",
                                                        "9AM - 6PM"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left">
                                                    {cmsElmt(
                                                        "Timeslot 5 Name",
                                                        "text",
                                                        "FRIDAY"
                                                    )}
                                                </td>
                                                <td className="right">
                                                    {cmsElmt(
                                                        "Timeslot 5 Time",
                                                        "text",
                                                        "9AM - 6PM"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left">
                                                    {cmsElmt(
                                                        "Timeslot 6 Name",
                                                        "text",
                                                        "SATURDAY"
                                                    )}
                                                </td>
                                                <td className="right">
                                                    {cmsElmt(
                                                        "Timeslot 6 Time",
                                                        "text",
                                                        "9AM - 5PM"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left">
                                                    {cmsElmt(
                                                        "Timeslot 7 Name",
                                                        "text",
                                                        "SUNDAY"
                                                    )}
                                                </td>
                                                <td className="right">
                                                    {cmsElmt(
                                                        "Timeslot 7 Time",
                                                        "text",
                                                        "11AM - 3PM"
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="button-container">
                                    <a href="https://www.fresha.com/providers/the-liffey-barbers-asbdu35x?pId=480075">
                                        <button className="btn-primary">
                                            {cmsElmt(
                                                "Button Text",
                                                "text",
                                                "BOOK NOW"
                                            )}
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inner-column">
                        <Map />
                    </div>
                </div>
            )}
        </CMSSection>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, getState) => ({});

export const HoursTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(HoursTableComponent);
