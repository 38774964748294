import { UPDATE_HEADER_POSITION } from "../actions/types";

const initialState = {
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_HEADER_POSITION:
            return {
                ...state,
                headerHeight: action.payload,
            };
        default:
            return state;
    }
}
