import {
    FETCH_CMS_SECTION,
    UPDATE_CMS,
    UPDATE_CMS_SECTION,
    CREATE_CMS_ELEMENT,
    CLEAR_CMS_SECTIONS,
    DELETE_CMS,
    FETCH_CMS_SECTION_BY_ID,
    CREATING_CMS_ELEMENT,
    UPDATE_CURRENT_CMS_SECTION,
} from "./types";
import { dgResolveObjString } from "dg-utils";
import { getAccessToken } from "./login.action";

export const fetchSection = (data) => async (dispatch) => {
    try {
        const cmsSectionResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/cms/section/`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        const cmsSection = await cmsSectionResponse.json();
        /*dispatch({
            type: FETCH_CMS_SECTION,
            payload: cmsSection.data,
        });*/
        if (cmsSection.ok) {
            return cmsSection;
        } else {
            throw cmsSection;
        }
    } catch (error) {
        throw error;
    }
};

// Create a data element in a section in the database
export const createElement = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/cms/element/create`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((res) => {})
            .catch((error) => {
                reject(error);
            });
    });
};

// Update the data associated with section in the redux store
export const sectionUpdate = (data) => {
    return {
        type: UPDATE_CMS_SECTION,
        payload: data,
    };
};

function containsObject(list, keyName, value) {
    let i;
    for (i = 0; i < list.length; i++) {
        if (list[i][keyName] === value) {
            return list[i];
        }
    }
    return false;
}

const objectIndex = (list, keyName, value) => {
    let i;
    for (i = 0; i < list.length; i++) {
        if (list[i][keyName] === value) {
            return i;
        }
    }
    return false;
};

// Add a state variable to matchsipatchtoprops in a redux container/component which is being imported from an async module
export const cmsStateAccess = (
    name,
    fieldType,
    defaultValue,
    sectionContext,
    state,
    store
) => {
    // Extract the array of data elements for this section from the section context
    let dataArray = sectionContext.data;
    // Find the element in qurestion
    let elementData = containsObject(dataArray, "name", name);

    if (elementData !== undefined && elementData !== false) {
        return elementData.value;
    } else {
        // In this case we have to create this key in the
        //let sectionData = containsObject("cms.sections." + sectionPath, state);
        let creatingId = sectionContext.id + name + fieldType + defaultValue;
        if (!state.cms.creatingElements.includes(creatingId)) {
            // Add this element to the list of ones which are being created currently (to prevent duplicate requests)
            state.cms.creatingElements.push(creatingId);
            store.dispatch({
                type: CREATING_CMS_ELEMENT,
                payload: state.cms.creatingElements,
            });
            // Create the element
            store.dispatch(
                createElement({
                    sectionId: sectionContext.id,
                    name,
                    fieldType,
                    value: defaultValue,
                })
            );
            return null;
        } else {
            return null;
        }
    }
};

const objToQuery = (object) =>
    Object.keys(object)
        .map((key) => key + "=" + object[key])
        .join("&");

// Fetch CMS sections based on level and id
// change to promose?
export const fetchCMSData = (criteria) => async (dispatch) => {
    try {
        const cmsSectionResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/cms/section/?${objToQuery(
                criteria
            )}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                },
            }
        );
        const cmsSection = await cmsSectionResponse.json();
        dispatch({
            type: FETCH_CMS_SECTION,
            payload: cmsSection,
        });
        if (cmsSection.ok) {
            return cmsSection;
        } else {
            throw cmsSection;
        }
    } catch (error) {
        throw error;
    }
};

// Clear sections value in store
export const clearSections = (data) => (dispatch) => {
    dispatch({
        type: CLEAR_CMS_SECTIONS,
        payload: data,
    });
};

export const updateCurrentCMSSection = (sectionData) => ({
    type: UPDATE_CURRENT_CMS_SECTION,
    payload: sectionData,
});

export const deleteCMS = (cmsId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/cms/delete/${cmsId}`, {
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                access_token: token,
            },
        })
            .then((res) => res.json())
            .then((post) => {
                dispatch({
                    type: DELETE_CMS,
                    payload: post,
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const updateCMSSection = (id, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        for (var pair of data.entries()) {
            console.log(pair[0] + " - " + pair[1]);
        }
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/cms/section/update/${id}`,
            {
                method: "PUT",
                headers: {},
                body: data,
            }
        )
            .then((res) => res.json())
            .then((post) => {
                dispatch({
                    type: UPDATE_CMS_SECTION,
                    payload: post.section,
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getCMSById = (cmsId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/cms/section/${cmsId}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                access_token: token,
            },
        })
            .then((res) => res.json())
            .then((post) => {
                dispatch({
                    type: FETCH_CMS_SECTION_BY_ID,
                    payload: post,
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};
