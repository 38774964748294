import React, { Component } from "react";
import { connect } from "react-redux";
import { Swipeable } from "react-swipeable";
import AOS from "aos";

// Styles
import "../../styles/components/gallery/gallery.scss";

// Assets
//import { slides } from "../../assets/files/gallery";
import arrowLeft from "../../assets/icons/gallery-arrow-left.svg";
import arrowRight from "../../assets/icons/gallery-arrow-right.svg";

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            slidesPerPage: 3,
            isAutoScrollOn: false,
            timing: 6000,
        };

        this.setIndex = this.setIndex.bind(this);

        if (this.state.isAutoScrollOn) {
            this.interval = setInterval(
                () => this.setIndex(this.state.page + 1),
                this.state.timing
            );
        }
    }

    componentDidMount() {
        AOS.init();
    }


    setIndex(newPage) {
        const maxPages =
            Math.ceil(
                this.props.slides.length /
                    (this.props.utility.isMobileSite
                        ? 1
                        : this.state.slidesPerPage)
            ) - 1;

        if (newPage > maxPages) {
            this.setState({ page: 0 });
        } else if (newPage < 0) {
            this.setState({ page: maxPages });
        } else {
            this.setState({ page: newPage });
        }

        // Reset autocycle to prevent jumpyness.
        if (this.state.isAutoScrollOn) {
            clearInterval(this.interval);
            this.interval = setInterval(
                () => this.setIndex(this.state.page + 1),
                this.state.timing
            );
        }

        // If Animation Library is passed in, reset animation.
        if (this.AOS) {
            this.AOS.refresh();
        }
    }

    render() {
        const swipeConfig = {
            onSwipedLeft: () => this.setIndex(this.state.page + 1),
            onSwipedRight: () => this.setIndex(this.state.page - 1),
            preventDefaultTouchmoveEvent: true,
            trackMouse: true,
        };
        let renderSlides = [];

        if (this.props.slides.length > 0) {
            if (this.props.utility.isMobileSite) {
                let start = this.state.page * 1;
                let end = start + 1;

                renderSlides = this.props.slides.slice(start, end).map((value, index) => (
                    <div
                        key={`gallery_slide_${index}`}
                        className="slide"
                        data-aos="fade-in"
                        data-aos-delay={index * 100}
                    >
                        <img src={value.imgSrc} alt={value.imgAlt} />
                    </div>
                ));
            } else {
                let start = this.state.page * this.state.slidesPerPage;
                let end = start + this.state.slidesPerPage;

                renderSlides = this.props.slides.slice(start, end).map((value, index) => (
                    <div
                        key={`gallery_slide_${index}`}
                        className="slide"
                        data-aos="fade-in"
                        data-aos-delay={index * 100}
                    >
                        <img src={value.imgSrc} alt={value.imgAlt} />
                    </div>
                ));
            }
        }

        return (
            <Swipeable {...swipeConfig} className="gallery-container">
                <div className="left">
                    <button
                        type="button"
                        className="gallery-button left"
                        onClick={() => this.setIndex(this.state.page - 1)}
                    >
                        <img src={arrowLeft} alt="gallery left" />
                    </button>
                </div>
                <div className="body">{renderSlides}</div>
                <div className="right">
                    <button
                        type="button"
                        className="gallery-button right"
                        onClick={() => this.setIndex(this.state.page + 1)}
                    >
                        <img src={arrowRight} alt="gallery right" />
                    </button>
                </div>
            </Swipeable>
        );
    }
}

const mapStateToProps = (state) => ({
    utility: state.utility,
});

export default connect(mapStateToProps, {
})(Gallery);
