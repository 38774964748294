import { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } from '../actions/types';

const initialState = {
    user: {},
    message: '',
    access_token: '',
    errors: []
};

export default function (state = initialState, action) {
    switch(action.type) {
        case LOGOUT: 
            return {
                ...state,
                user: {},
                access_token: '',
                message: 'Logged Out User',
                errors: []
            }
        case LOGIN_SUCCESS: 
            return {
                ...state,
                user: action.payload.user,
                access_token: action.payload.access_token,
                message: action.payload.message
            }
        case LOGIN_FAILED: 
            return {
                ...state,
                message: action.payload.message,
                errors: action.payload.errors
            }
        default: 
            return state;
    }
}