import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute.component";
import { store } from "./store";
import history from "./history";

// Library Styles
import "redux-blogs/dist/index.css";
import "dg-forms/dist/index.css";
import "dg-pagination/dist/index.css";
import "dg-utils/dist/index.css";

//Consumer Pages
import { Home } from "./pages/Home";

// Admin Pages
import { LoginPage } from "./pages/admin/login/LoginPage";
import {Dashboard} from "./pages/admin/dashboard/Dashboard";

import ListContainer from "./components/admin/cms/CMSList";
import EditContainer from "./components/admin/cms/editCMS";

class App extends React.Component {
    authenticate() {
        return new Promise((resolve) => setTimeout(resolve, 10)); // 10ms
    }

    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/login" exact component={LoginPage} />
                        <ProtectedRoute
                            path="/dashboard"
                            exact
                            component={Dashboard}
                        />
                        <ProtectedRoute
                            path="/dashboard/cms"
                            exact
                            component={ListContainer}
                        />
                        <ProtectedRoute
                            path="/dashboard/cms/:id"
                            exact
                            component={EditContainer}
                        />
                    </Switch>
                </Router>
            </Provider>
        );
    }
}

export default App;
