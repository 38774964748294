import React from "react";

//Components
import { Social } from "../social/Social";
import { BackgroundImage } from "../blocks/backgroundImage";

//Assets
import woodImage from "../../assets/images/wood-background-texture-1.jpg"
import woodImageWebP from "../../assets/images/wood-background-texture-1.webp"
import woodMobile from "../../assets/images/mobile/wood-background-texture-1.jpg"
import woodMobileWebP from "../../assets/images/mobile/wood-background-texture-1.webp"

//Styles
import "../../styles/components/footer/Footer.scss";

export const Footer = () => {
    return (
        <div className="footer-container">
            <BackgroundImage image={woodImage} imageWebP={woodImageWebP} mobile={woodMobile} mobileWebP={woodMobileWebP}/>
            <div className="content-container">
                <div className="footer-social-container">
                    <Social />
                </div>
                <div className="signature">
                    <a
                        href="http://www.digigrow.ie"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        A Digigrow Production
                    </a>
                </div>
            </div>
        </div>
    );
};
