import React from "react";

//Components
import { DgPicture } from "dg-utils";

//Styles
import "../../styles/components/blocks/backgroundImage.scss";

export const BackgroundImage = ({ image, imageWebP, mobile, mobileWebP }) => {
    return (
        <div className="background-image-container">
            <div className="image-container">
                <DgPicture>
                    <source
                        media="(max-width: 1024px)"
                        srcSet={mobileWebP}
                        type="image/webp"
                    />
                    <source
                        media="(max-width: 1024px)"
                        srcSet={mobile}
                        type="image/png"
                    />
                    <source srcSet={imageWebP} type="image/webp" />
                    <img src={image} alt={"background-image"} />
                </DgPicture>
            </div>
        </div>
    );
};
