import React from "react";
import { connect } from "react-redux";

//Components
import { NavLink } from "react-router-dom";
import { ContactIcons } from "../contactIcons/ContactIcons";
import MobileMenu from "./MobileMenu";

//Actions
import { updateHeaderPosition } from "../../actions/updateHeaderPosition";
import { setIsMobileSite } from "../../actions/utility";

//Styles
import "../../styles/components/navbar/NavBar.scss";

//Assets
import logo from "../../assets/logos/liffey-barbers-white-grey.svg";

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            altTheme: "",
        };
    }

    //If the page contains a header, apply primary class to navbar only after scrolling beyond the header.
    //This turned out to be not a great solution, functional but not clean.
    //Also not rerendering navbar on navigation to new page, even though store has updated.
    altTheme = () => {
        const headerlessPages = ["/services", "/fees"];
        const changeTheme =
            headerlessPages.includes(window.location.pathname) ||
            window.scrollY > this.props.headerHeight;
        this.setState({ altTheme: changeTheme ? "-primary" : "" });
    };

    checkIsMobileDevice = () => {
        let isMobileDevice = window.innerWidth <= this.props.utility.mobileBreakpoint + 1;
        this.props.setIsMobileSite(isMobileDevice);
    };

    componentDidMount() {
        this.checkIsMobileDevice();
        this.altTheme();
        window.addEventListener("scroll", this.altTheme);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.altTheme);
    }

    render() {
        return (
            <div className={`navbar-container ${this.state.altTheme}`}>
                <div className="navbar">
                    <div className="logo-container">
                        <NavLink to="/">
                            <img src={logo} alt="Liffey Barbers Logo" />
                        </NavLink>
                    </div>
                    <div className="navbar-right">
                        <div className="navbar-links-container">
                            <ul>
                                <li>
                                    <NavLink
                                        className={`navbar-link ${this.state.altTheme}`}
                                        to="/"
                                        onClick={this.props.scrollToAbout}
                                    >
                                        ABOUT US
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={`navbar-link ${this.state.altTheme}`}
                                        onClick={this.props.scrollToPricing}
                                        to="/"
                                    >
                                        PRICING
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={`navbar-link ${this.state.altTheme}`}
                                        to="/"
                                        onClick={this.props.scrollToHours}
                                    >
                                        HOURS OF OPERATION
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={`navbar-link ${this.state.altTheme}`}
                                        to="/"
                                        onClick={this.props.scrollToReviews}
                                    >
                                        REVIEWS
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={`navbar-link ${this.state.altTheme}`}
                                        to="/"
                                        onClick={this.props.scrollToContact}
                                    >
                                        CONTACT US
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <MobileMenu
                        scrollToAbout={this.props.scrollToAbout}
                        scrollToHours={this.props.scrollToHours}
                        scrollToPricing={this.props.scrollToPricing}
                        scrollToReviews={this.props.scrollToReviews}
                        scrollToContact={this.props.scrollToContact}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    headerHeight: state.header.headerHeight,
    utility: state.utility,
});

export default connect(mapStateToProps, {
    updateHeaderPosition,
    setIsMobileSite,
})(NavBar);
