export const CREATE_CONTACT = "CREATE_CONTACT";
export const FETCH_CONTACTS_REQUEST = "FETCH_CONTACTS_REQUEST";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
export const FETCH_CONTACTS_FAILURE = "FETCH_CONTACTS_FAILURE";
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";

export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILED = "GET_REVIEWS_FAILED";

export const FETCH_POSTS = "FETCH_POSTS";
export const NEW_POST = "NEW_POST";
export const SELECT_POST = "SELECT_POST";
export const CLEAR_POST_FORM = "CLEAR_POST_FORM";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

export const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU";

export const UPDATE_HEADER_POSITION = "UPDATE_HEADER_POSITION";
export const UPDATE_IS_MOBILE_SITE = "UPDATE_IS_MOBILE_SITE";

// CMS
export const FETCH_CMS_SECTION = "FETCH_CMS_SECTION";
export const UPDATE_CMS_SECTION = "UPDATE_CMS_SECTION";
export const CREATE_CMS_ELEMENT = "CREATE_CMS_ELEMENT";
export const GET_ALL_CMS = "GET_ALL_CMS";
export const DELETE_CMS = "DELETE_CMS";
export const UPDATE_CMS = "UPDATE_CMS";
export const FETCH_CMS_SECTION_BY_ID = "FETCH_CMS_SECTION_BY_ID";
export const CREATING_CMS_ELEMENT = "CREATING_CMS_ELEMENT";
export const CLEAR_CMS_SECTIONS = "CLEAR_CMS_SECTIONS";
export const UPDATE_CURRENT_CMS_SECTION = "UPDATE_CURRENT_CMS_SECTION";
