import React from "react";
import { connect } from "react-redux";

//Styles
import "../../styles/components/social/social.scss";

// CMS
import CMSSection from "../../components/cms/cms";

const InstagramIcon = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM19.5034 11.7472C20.9257 11.6825 21.3801 11.6667 25.0012 11.6667H24.9971C28.6193 11.6667 29.0721 11.6825 30.4944 11.7472C31.9138 11.8122 32.8833 12.0369 33.7333 12.3667C34.6111 12.707 35.3527 13.1625 36.0944 13.9042C36.8361 14.6453 37.2917 15.3892 37.6333 16.2662C37.9611 17.1139 38.1861 18.0828 38.2528 19.5023C38.3167 20.9245 38.3333 21.379 38.3333 25.0001C38.3333 28.6213 38.3167 29.0746 38.2528 30.4969C38.1861 31.9158 37.9611 32.8849 37.6333 33.733C37.2917 34.6097 36.8361 35.3536 36.0944 36.0947C35.3536 36.8364 34.6108 37.293 33.7341 37.6336C32.8858 37.9633 31.9158 38.1881 30.4963 38.2531C29.0741 38.3178 28.621 38.3336 24.9996 38.3336C21.3787 38.3336 20.9245 38.3178 19.5023 38.2531C18.0831 38.1881 17.1139 37.9633 16.2656 37.6336C15.3892 37.293 14.6453 36.8364 13.9045 36.0947C13.1631 35.3536 12.7075 34.6097 12.3667 33.7327C12.0372 32.8849 11.8125 31.916 11.7472 30.4966C11.6828 29.0743 11.6667 28.6213 11.6667 25.0001C11.6667 21.379 11.6833 20.9243 11.7469 19.502C11.8108 18.0831 12.0358 17.1139 12.3664 16.2659C12.7081 15.3892 13.1636 14.6453 13.9053 13.9042C14.6464 13.1628 15.3903 12.7072 16.2673 12.3667C17.1151 12.0369 18.084 11.8122 19.5034 11.7472Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.8051 14.0695C24.0374 14.0691 24.2872 14.0692 24.5568 14.0694L25.0012 14.0695C28.5613 14.0695 28.9832 14.0823 30.3891 14.1462C31.6891 14.2056 32.3946 14.4228 32.8646 14.6053C33.4869 14.847 33.9305 15.1359 34.3969 15.6026C34.8636 16.0692 35.1524 16.5137 35.3947 17.1359C35.5772 17.6054 35.7947 18.3109 35.8538 19.6109C35.9177 21.0165 35.9316 21.4387 35.9316 24.9971C35.9316 28.5555 35.9177 28.9777 35.8538 30.3833C35.7944 31.6833 35.5772 32.3889 35.3947 32.8583C35.153 33.4805 34.8636 33.9236 34.3969 34.39C33.9302 34.8567 33.4872 35.1455 32.8646 35.3872C32.3952 35.5706 31.6891 35.7872 30.3891 35.8467C28.9835 35.9106 28.5613 35.9244 25.0012 35.9244C21.4409 35.9244 21.019 35.9106 19.6134 35.8467C18.3134 35.7867 17.6078 35.5694 17.1375 35.3869C16.5153 35.1453 16.0709 34.8564 15.6042 34.3897C15.1375 33.923 14.8486 33.4797 14.6064 32.8572C14.4239 32.3877 14.2064 31.6822 14.1472 30.3822C14.0833 28.9766 14.0706 28.5544 14.0706 24.9938C14.0706 21.4332 14.0833 21.0132 14.1472 19.6076C14.2067 18.3076 14.4239 17.602 14.6064 17.132C14.8481 16.5098 15.1375 16.0653 15.6042 15.5987C16.0709 15.132 16.5153 14.8431 17.1375 14.6009C17.6075 14.4176 18.3134 14.2009 19.6134 14.1412C20.8434 14.0856 21.3201 14.0689 23.8051 14.0662V14.0695ZM32.1185 16.2835C31.2352 16.2835 30.5185 16.9994 30.5185 17.883C30.5185 18.7663 31.2352 19.483 32.1185 19.483C33.0019 19.483 33.7185 18.7663 33.7185 17.883C33.7185 16.9996 33.0019 16.283 32.1185 16.283V16.2835ZM18.1539 25.0002C18.1539 21.2188 21.2196 18.153 25.0009 18.1529C28.7824 18.1529 31.8474 21.2187 31.8474 25.0002C31.8474 28.7816 28.7826 31.8461 25.0012 31.8461C21.2198 31.8461 18.1539 28.7816 18.1539 25.0002Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.0013 20.5557C27.4557 20.5557 29.4457 22.5454 29.4457 25.0002C29.4457 27.4546 27.4557 29.4446 25.0013 29.4446C22.5465 29.4446 20.5568 27.4546 20.5568 25.0002C20.5568 22.5454 22.5465 20.5557 25.0013 20.5557V20.5557Z"
                fill="white"
            />
        </svg>
    );
};

const TwitterIcon = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM24.3037 21.1854L24.2513 20.3203C24.0939 18.0782 25.4753 16.0303 27.6612 15.2359C28.4656 14.9534 29.8296 14.9181 30.7214 15.1653C31.0712 15.2712 31.7357 15.6243 32.2078 15.9421L33.0647 16.5247L34.009 16.2245C34.5336 16.0656 35.2331 15.8008 35.5478 15.6243C35.8451 15.4654 36.1074 15.3771 36.1074 15.4301C36.1074 15.7302 35.4604 16.7542 34.9183 17.3191C34.1839 18.1135 34.3937 18.1842 35.8801 17.6545C36.7719 17.3544 36.7894 17.3544 36.6145 17.6898C36.5096 17.8664 35.9675 18.4843 35.3905 19.0492C34.4112 20.0202 34.3587 20.1261 34.3587 20.9382C34.3587 22.1917 33.7642 24.8045 33.1696 26.2345C32.0679 28.9179 29.7072 31.6896 27.3464 33.0843C24.0239 35.0439 19.5997 35.5382 15.875 34.3907C14.6334 34.0023 12.5 33.0137 12.5 32.8372C12.5 32.7842 13.147 32.7136 13.9339 32.6959C15.5777 32.6606 17.2215 32.2016 18.6204 31.3895L19.5647 30.8246L18.4805 30.4538C16.9417 29.9242 15.5602 28.7061 15.2105 27.5585C15.1056 27.1878 15.1405 27.1701 16.1198 27.1701L17.1341 27.1525L16.2772 26.7464C15.2629 26.2345 14.3361 25.3694 13.8815 24.4867C13.5492 23.8512 13.1295 22.2446 13.2519 22.121C13.2869 22.0681 13.6541 22.174 14.0738 22.3152C15.2804 22.7566 15.4378 22.6507 14.7383 21.9092C13.4268 20.5675 13.0246 18.5725 13.6541 16.6835L13.9514 15.8361L15.1056 16.9837C17.4663 19.2964 20.2467 20.6734 23.4294 21.0794L24.3037 21.1854Z"
                fill="white"
            />
        </svg>
    );
};

const FacebookIcon = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM27.6058 26.0981V39.6995H21.9782V26.0987H19.1667V21.4116H21.9782V18.5975C21.9782 14.7737 23.5658 12.5 28.0762 12.5H31.8313V17.1876H29.4841C27.7283 17.1876 27.6122 17.8426 27.6122 19.0651L27.6058 21.411H31.8579L31.3603 26.0981H27.6058Z"
                fill="white"
            />
        </svg>
    );
};

const LinkedInIcon = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM12.0017 20.706H17.6672V37.7286H12.0017V20.706ZM18.0404 15.4403C18.0036 13.7713 16.8101 12.5 14.8719 12.5C12.9338 12.5 11.6667 13.7713 11.6667 15.4403C11.6667 17.0748 12.8963 18.3827 14.7984 18.3827H14.8346C16.8101 18.3827 18.0404 17.0748 18.0404 15.4403ZM31.5762 20.3064C35.3044 20.3064 38.0994 22.7399 38.0994 27.9685L38.0991 37.7287H32.4338V28.6216C32.4338 26.3341 31.614 24.7732 29.563 24.7732C27.9978 24.7732 27.0655 25.8255 26.656 26.8419C26.5062 27.2061 26.4694 27.7136 26.4694 28.2224V37.7292H20.8032C20.8032 37.7292 20.8779 22.3039 20.8032 20.7066H26.4694V23.1177C27.2214 21.9586 28.5681 20.3064 31.5762 20.3064Z"
                fill="white"
            />
        </svg>
    );
};

const SocialComponent = ({cms}) => {
    return (
        <CMSSection name="Social">
            {({cmsElmt}) => (
                <div className="social-container">
                    <div className="join-us-container">
                        <h2>
                            {cmsElmt(
                                "Header",
                                "text",
                                "JOIN US ON SOCIAL",
                            )}
                        </h2>
                    </div>
                    <ul className="social-list">
                        <li>
                            <a
                                className="social"
                                href="https://www.facebook.com/pages/category/Barber-Shop/Liffey-Barbers-376083232990542/"
                            >
                                <FacebookIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                className="social"
                                href="https://www.instagram.com/liffeybarberscelbridge/?hl=en"
                            >
                                <InstagramIcon />
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </CMSSection>
    );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch, getState) => ({});

export const Social = connect(
    mapStateToProps,
    mapDispatchToProps
)(SocialComponent);
