import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import { reducer as blogReducer } from "redux-blogs";
import loginReducer from "./login.reducer";
import { fetchContactsReducer } from "./fetchContactsReducer";
import toggleMobileMenu from "./toggleMobileMenu.js";
import updateHeaderHeight from "./headerReducer";
import utility from "./utility";
import cms from "./cmsReducer";
import reviewsReducer from './reviews.reducer';

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        loggedInUser: loginReducer,
        form: formReducer,
        blogs: blogReducer,
        contacts: fetchContactsReducer,
        mobile: toggleMobileMenu,
        header: updateHeaderHeight,
        reviews: reviewsReducer,
        utility: utility,
        cms: cms,
    });

export default createRootReducer;
